import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoggedUserProvider } from './contexts/LoggedUser';
import { DeliveryProvider } from './contexts/Delivery';
import { ComponentProvider } from 'contexts/Component';
import { NotificationProvider } from 'contexts/notification-provider/NotificationProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <NotificationProvider>
        <LoggedUserProvider>
          <DeliveryProvider>
            <ComponentProvider>

              <App />

            </ComponentProvider>
          </DeliveryProvider>
        </LoggedUserProvider>
      </NotificationProvider>
    </Router>
  </React.StrictMode>
);
reportWebVitals();
