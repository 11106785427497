import React, { useState } from 'react';
import axios from 'axios';
import styles from './styles.module.scss';

const ExportToExcel = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(false);

  const fetchDataAndExportToExcel = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${API_BASE_URL}/components-raports/all-components/excel`, {
        responseType: 'blob', // <-- ważne, żeby pobrać plik binarny
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Możesz ustawić nazwę pliku tutaj
      link.setAttribute('download', 'raport-komponentow.xlsx');

      document.body.appendChild(link);
      link.click();

      // Czyszczenie
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Błąd podczas eksportu do Excela:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.btnWrapper}>
      <button className={styles.btn} onClick={fetchDataAndExportToExcel} disabled={loading}>
        {loading ? 'Wczytywanie...' : 'Export do Excel'}
      </button>
    </div>
  );
};

export default ExportToExcel;